export default {
    /**
       * DataObject to be used by the lookup
       * @type Function
       */
    dataObject: {
        required: false
    },
    /**
     * Any data array for the lookup list
     * @type any[].
     */
    data: null,
    id: String,
    /**
    * bind function
    * @value sel=>{row.field=sel.field}
    */
    bind: Function,
    /**
   * This used for value binding  v-model. If used, value can not be used anymore.
   *  @model
   * @value row.field
   */
    modelValue: {},
    /**
     * When true will enable multiselect mode on the lookp, the bind function will
     * recieve an array of selected rows instead of just one row.
     */
    multiselect: {
        type: Boolean,
        default: false
    },
    /** When true the lookup will not inlcude the filter row */
    noSearch: {
        type: Boolean,
        default: false
    },
    /** Disable lookup clearing */
    noClear: {
        type: [Boolean, String],
        default: false
    },
    suppressClickEvent: {
        type: Boolean,
        default: false
    },
    /** Add a focus event on the target that will open the lookup */
    openonfocus: {
        type: Boolean,
        default: false
    },
    /** Disable recents for this lookup */
    disableRecent: {
        type: Boolean,
        default: false
    },
    /** Disable pinned rows for this lookup */
    disablePinned: {
        type: Boolean,
        default: false
    },
    /**
     * Search input was removed in favor of filterRow
     * @deprecated
     */
    useSearchColumn: {
        type: Boolean,
        default: false
    },
    /**
     * When defined will be called on clear. Otherwise clear will call bind function with
     * an object there all indexes are null or if in multiselect mode then it will return an empty array
     */
    bindClear: Function,
    label: {
        type: String,
        required: false
    },
    /**
    *Used when default columns slot is not defined
    */
    columns: Array,
    /**
   * This should be used if you want to allow user only select from list, no typing. If used, v-model can not be used anymore.
   */
    value: {},
    /**
     * The preffered way to provide a where clause to a lookup. If you need the lookup to reload on any where clause change you can also
     * add 'reloadOnWhereClauseChange' prop.
     */
    whereClause: String,
};
